const jwt = {

    //如果JWT过期，则删除JWT
    getJwtToken() {
        const token = localStorage.getItem("jwt_token");
        if (!token) {
            return null;
        }

        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            const currentTime = Math.floor(Date.now() / 1000);

            if (payload.exp && payload.exp < currentTime) {
                this.removeJwtToken();
                return null;
            }
        } catch (e) {
            // 如果解析失败，删除JWT
            this.removeJwtToken();
            return null;
        }

        return token;
    },


    // 设置JWT
    setJwtToken(token) {
        try {
            const payload = JSON.parse(atob(token.split('.')[1]));

            if (payload.UserId !== undefined && payload.Username !== undefined) {
                localStorage.setItem("jwt_token", token);
                return true;
            } else {
                return false;
            }
        } catch (e) {
            // 如果解析失败，返回false
            return false;
        }
    },
    // 删除JWT
    removeJwtToken() {
        localStorage.removeItem("jwt_token");
    },
    // 获取UserId
    getUserId() {
        const token = this.getJwtToken();
        if (!token) {
            return null;
        }

        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.UserId !== undefined ? payload.UserId : null;
        } catch (e) {
            return null;
        }
    },
    // 获取Username
    getUsername() {
        const token = this.getJwtToken();
        if (!token) {
            return null;
        }

        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.Username !== undefined ? payload.Username : null;
        } catch (e) {
            return null;
        }
    }
}

export default jwt;