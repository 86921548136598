import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../components/TranslatePage')
    },
    {
        path: '/yaml_tran',
        name: 'yaml_tran',
        component: () => import('../components/TranslatePage')
    },
    {
        path: '/login',
        name: '/login',
        component: () => import('../components/LoginPage')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../components/RegisterPage')
    },
    {
        path: '/forgetpassword',
        name: 'forgetpassword',
        component: () => import('../components/ForgetPasswordPage')
    },
    {
        path: '/packet',
        name: 'packet',
        component: () => import('../components/PacketPage')
    },
    {
        path: '/tasklist',
        name: 'tasklist',
        component: () => import('../components/TaskListPage')
    },
    {
        path: '/balance_log',
        name: 'balance_log',
        component: () => import('../components/BalanceLogPage')
    },
]

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes
})

export default router
