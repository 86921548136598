<template>
  <IndexPage />
</template>

<script>
import IndexPage from './components/IndexPage.vue'

// import api from './api/index'

export default {
  name: 'App',
  components: {
    IndexPage
  },
  beforeCreate() {
    // api.getUserInfo().then((response) => {
    //   if (response.data.code == 0) {
    //     this.$store.commit("setUserName", response.data.username)
    //   } else {
    //     this.$store.commit("setUserName", "")
    //   }
    // }).catch((error) => {
    //   console.error('An error occurred:', error);
    // });

  },
}

</script>

<style></style>
