import { createStore } from "vuex";

export default createStore({
    state: {
        userName: "",
        index_path_select: "",
    },
    getters: {
        getUserName(state) {
            return state.userName;
        },

        getIndexPathSelect(state) {
            return state.index_path_select;
        },
    },
    mutations: {
        setUserName(state, userName) {
            state.userName = userName;
        },
        setIndexPathSelect(state, index_path_select) {
            state.index_path_select = index_path_select;
        },
    },

    actions: {
        updateIndexPathSelect({ commit }, index_path_select) {
            commit('setIndexPathSelect', index_path_select);
        },
    }
});
