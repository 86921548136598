<template>
    <el-container>
        <el-header>
            <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" :ellipsis="false"
                @select="handleSelect">

                <el-menu-item index="yaml_tran">YAML翻译</el-menu-item>
                <el-menu-item index="tasklist">任务列表</el-menu-item>
                <el-menu-item index="packet">钱包</el-menu-item>
                <el-menu-item index="balance_log">硬币日志</el-menu-item>
                <el-menu-item index="add_qq_group">加入QQ反馈群</el-menu-item>

                <div class="flex-grow" />
                <el-menu-item v-if="!isLogin" index="login"><el-icon>
                        <Key />
                    </el-icon>登录</el-menu-item>
                <el-menu-item v-if="!isLogin" index="register"><el-icon>
                        <UserFilled />
                    </el-icon>注册</el-menu-item>
                <el-sub-menu v-if="isLogin" index="username">
                    <template #title>{{ username }}</template>
                    <el-menu-item index="forgetpassword">修改密码</el-menu-item>
                    <el-menu-item index="logout">退出登录</el-menu-item>
                </el-sub-menu>
            </el-menu>
        </el-header>
        <el-main padding="0">
            <router-view></router-view>
        </el-main>
    </el-container>
</template>

<script>

import jwt from '../utils/jwt'
import { mapState, mapGetters } from 'vuex';

export default {

    name: 'IndexPage',
    props: {
        msg: String
    },
    data() {
        return {
            isLogin: false,
            username: '',
        }
    },
    mounted() {
        //获取herf并设置对应的activeIndex
        this.$store.commit("setIndexPathSelect", window.location.href.split("/").pop())
        this.username = jwt.getUsername()
        if (this.username) {
            this.isLogin = true;
        } else {
            this.isLogin = false;
        }
    },
    methods: {
        handleSelect(key) {
            this.$store.commit('setIndexPathSelect', key);
            if (key == 'login') {
                this.$router.push('/login')
            } else if (key == 'register') {
                this.$router.push('/register')
            } else if (key == 'forgetpassword') {
                this.$router.push('/forgetpassword')
            } else if (key == 'yaml_tran') {
                this.$router.push('/yaml_tran')
            } else if (key == 'packet') {
                this.$router.push('/packet')
            } else if (key == 'logout') {
                jwt.removeJwtToken()
                window.location.reload()
            } else if (key == 'tasklist') {
                this.$router.push('/tasklist')
            } else if (key == 'balance_log') {
                this.$router.push('/balance_log')
            } else if (key == 'add_qq_group') {
                window.open('http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=M-CjxdAKWfpPjIL5wwuWMHrdXy4wUkJT&authKey=auf1XEtcRO0JtYnGTffuuDopghSUOlg4BIx3QIx1Pbmrz4hH1Z156ubVGvQxeOXj&noverify=0&group_code=824924577')
            }
        }
    },
    computed: {
        ...mapState(['index_path_select']),
        ...mapGetters(['getIndexPathSelect']),
        activeIndex() {
            return this.index_path_select;
        }
    },
    watch: {
        index_path_select(newVal) {
            this.handleSelect(newVal);
        }
    },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.el-menu {
    z-index: 1000;
}

.el-header {
    position: relative;
    width: 100%;
    height: 60px;
    padding: 0%;
}

.el-main {
    position: absolute;
    padding: 0px !important;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    overflow-y: 0;
}

.flex-grow {
    flex-grow: 1;
}
</style>